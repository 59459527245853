var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.commentTicket,
                  },
                  on: { click: _vm.commentTicketClicked },
                },
                [_vm._v(_vm._s(_vm.$t("comment")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.correctionTicket,
                  },
                  on: { click: _vm.correctionTicketClicked },
                },
                [_vm._v(_vm._s(_vm.$t("correction")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.remarkTicket,
                  },
                  on: { click: _vm.remarkTicketClicked },
                },
                [_vm._v(_vm._s(_vm.$t("remark")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "200",
                    disabled: !_vm.threeHourDeepNoticeTicket,
                  },
                  on: { click: _vm.deepNoticeTicket },
                },
                [_vm._v(_vm._s(_vm.$t("ThreeHourDeepNotice")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "150",
                    disabled: !_vm.threeHourNoticeTicket,
                  },
                  on: { click: _vm.hourNoticeTicket },
                },
                [_vm._v(_vm._s(_vm.$t("threeHrNoticeV")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.updateTicketVups,
                  },
                  on: { click: _vm.updateTicket },
                },
                [_vm._v(_vm._s(_vm.$t("update")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.cancelVups,
                  },
                  on: { click: _vm.cancelTicket },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.commentTicket,
                  },
                  on: { click: _vm.commentTicketClicked },
                },
                [_vm._v(_vm._s(_vm.$t("comment")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.correctionTicket,
                  },
                  on: { click: _vm.correctionTicketClicked },
                },
                [_vm._v(_vm._s(_vm.$t("correction")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.remarkTicket,
                  },
                  on: { click: _vm.remarkTicketClicked },
                },
                [_vm._v(_vm._s(_vm.$t("remark")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "200",
                    disabled: !_vm.threeHourDeepNoticeTicket,
                  },
                  on: { click: _vm.deepNoticeTicket },
                },
                [_vm._v(_vm._s(_vm.$t("ThreeHourDeepNotice")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "150",
                    disabled: !_vm.threeHourNoticeTicket,
                  },
                  on: { click: _vm.hourNoticeTicket },
                },
                [_vm._v(_vm._s(_vm.$t("threeHrNoticeV")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text mr-4",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.updateTicketVups,
                  },
                  on: { click: _vm.updateTicket },
                },
                [_vm._v(_vm._s(_vm.$t("update")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "text-none rounded-lg font-weight-regular mt-2 white--text",
                  attrs: {
                    color: "#1E853A",
                    width: "115",
                    disabled: !_vm.cancelVups,
                  },
                  on: { click: _vm.cancelTicket },
                },
                [_vm._v(_vm._s(_vm.$t("cancel")))]
              ),
            ],
            1
          ),
      _vm.cancelTicketDialog
        ? _c("CancelTicketDialogVups", {
            attrs: {
              "ticket-data": _vm.ticketDetails,
              dialog: _vm.cancelTicketDialog,
            },
            on: {
              cancelTicketSubmit: _vm.cancelTicketSubmit,
              closeDialog: _vm.closeCancelTicketDialog,
              cancelTicketSuccess: _vm.cancelTicketSuccess,
              errorSubmitTicket: _vm.errorSubmitTicket,
            },
          })
        : _vm._e(),
      _vm.commentTicketDialog
        ? _c("CommentTicketDialogVups", {
            attrs: {
              "ticket-data": _vm.ticketDetails,
              dialog: _vm.commentTicketDialog,
            },
            on: {
              commentTicketSubmit: _vm.commentTicketSubmit,
              closeDialog: _vm.closeCommentTicketDialog,
              commentTicketSuccess: _vm.commentTicketSuccess,
              errorSubmitTicket: _vm.errorSubmitTicket,
            },
          })
        : _vm._e(),
      _vm.deepNoticeDialog
        ? _c("DeepNoticeDialog", {
            attrs: {
              "ticket-data": _vm.ticketDetails,
              dialog: _vm.deepNoticeDialog,
            },
            on: {
              deepNoticeSubmit: _vm.deepNoticeSubmit,
              closeDialog: _vm.closeDeepNoticeDialog,
              deepNoticeSuccess: _vm.deepNoticeSuccess,
              errorSubmitTicket: _vm.errorSubmitTicket,
            },
          })
        : _vm._e(),
      _vm.hourNoticeTicketDialog
        ? _c("HourNotice", {
            attrs: {
              "ticket-data": _vm.ticketDetails,
              dialog: _vm.hourNoticeTicketDialog,
            },
            on: {
              hourNoticeTicketSubmit: _vm.hourNoticeTicketSubmit,
              closeHourNoticeTicketDialog: _vm.closeHourNoticeTicketDialog,
              hourNoticeTicketSuccess: _vm.hourNoticeTicketSuccess,
              errorSubmitTicket: _vm.errorSubmitTicket,
            },
          })
        : _vm._e(),
      _vm.remarkTicketDialog
        ? _c("RemarkTicketDialogVups", {
            attrs: {
              "ticket-data": _vm.ticketDetails,
              dialog: _vm.remarkTicketDialog,
            },
            on: {
              remarkTicketSubmit: _vm.remarkTicketSubmit,
              closeRemarkDialog: _vm.closeRemarkTicketDialog,
              remarkTicketSuccess: _vm.remarkTicketSuccess,
              errorSubmitTicket: _vm.errorSubmitTicket,
            },
          })
        : _vm._e(),
      _vm.correctionTicketDialog
        ? _c("CorrectionTicketDialogVups", {
            attrs: {
              "ticket-data": _vm.ticketDetails,
              dialog: _vm.correctionTicketDialog,
            },
            on: {
              correctionTicketSubmit: _vm.remarkTicketSubmit,
              closeCorrectionDialog: _vm.closeCorrectionTicketDialog,
              correctionTicketSuccess: _vm.correctionTicketSuccess,
              errorSubmitTicket: _vm.errorSubmitTicket,
            },
          })
        : _vm._e(),
      _vm.updateTicketDialog
        ? _c("UpdateTicketsDialogVups", {
            attrs: {
              dialog: _vm.updateTicketDialog,
              "ticket-data": _vm.ticketDetails,
            },
            on: {
              updateTicketSubmit: _vm.updateTicketSubmit,
              closeUpdateTicket: _vm.closeUpdateTicketDialog,
              updateTicketSuccess: _vm.updateTicketSuccess,
              errorSubmitTicket: _vm.errorSubmitTicket,
            },
          })
        : _vm._e(),
      _vm.isError
        ? _c("ErrorMessage", {
            attrs: {
              "error-code": _vm.errorCode,
              "error-message": _vm.errorMessage,
            },
            on: {
              ticketScreen: function ($event) {
                _vm.isError = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }