<template>
  <div>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!commentTicket"
        @click="commentTicketClicked"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("comment") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!correctionTicket"
        @click="correctionTicketClicked"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("correction") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!remarkTicket"
        @click="remarkTicketClicked"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("remark") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="200"
        :disabled="!threeHourDeepNoticeTicket"
        @click="deepNoticeTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("ThreeHourDeepNotice") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="150"
        :disabled="!threeHourNoticeTicket"
        @click="hourNoticeTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("threeHrNoticeV") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!updateTicketVups"
        @click="updateTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("update") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        class="text-none rounded-lg font-weight-regular mt-2 white--text"
        :disabled="!cancelVups"
        @click="cancelTicket"
        >{{ $t("cancel") }}</v-btn
      >
    </div>
    <div v-else>
      <v-btn
        color="#1E853A"
        width="115"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        :disabled="!commentTicket"
        @click="commentTicketClicked"
        >{{ $t("comment") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!correctionTicket"
        @click="correctionTicketClicked"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("correction") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!remarkTicket"
        @click="remarkTicketClicked"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("remark") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="200"
        :disabled="!threeHourDeepNoticeTicket"
        @click="deepNoticeTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("ThreeHourDeepNotice") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="150"
        :disabled="!threeHourNoticeTicket"
        @click="hourNoticeTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("threeHrNoticeV") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        @click="updateTicket"
        :disabled="!updateTicketVups"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("update") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        class="text-none rounded-lg font-weight-regular mt-2 white--text"
        @click="cancelTicket"
        :disabled="!cancelVups"
        >{{ $t("cancel") }}</v-btn
      >
    </div>

    <CancelTicketDialogVups
      v-if="cancelTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="cancelTicketDialog"
      @cancelTicketSubmit="cancelTicketSubmit"
      @closeDialog="closeCancelTicketDialog"
      @cancelTicketSuccess="cancelTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />

    <CommentTicketDialogVups
      v-if="commentTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="commentTicketDialog"
      @commentTicketSubmit="commentTicketSubmit"
      @closeDialog="closeCommentTicketDialog"
      @commentTicketSuccess="commentTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <DeepNoticeDialog
      v-if="deepNoticeDialog"
      :ticket-data="ticketDetails"
      :dialog="deepNoticeDialog"
      @deepNoticeSubmit="deepNoticeSubmit"
      @closeDialog="closeDeepNoticeDialog"
      @deepNoticeSuccess="deepNoticeSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />

    <HourNotice
      v-if="hourNoticeTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="hourNoticeTicketDialog"
      @hourNoticeTicketSubmit="hourNoticeTicketSubmit"
      @closeHourNoticeTicketDialog="closeHourNoticeTicketDialog"
      @hourNoticeTicketSuccess="hourNoticeTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <RemarkTicketDialogVups
      v-if="remarkTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="remarkTicketDialog"
      @remarkTicketSubmit="remarkTicketSubmit"
      @closeRemarkDialog="closeRemarkTicketDialog"
      @remarkTicketSuccess="remarkTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <CorrectionTicketDialogVups
      v-if="correctionTicketDialog"
      :ticket-data="ticketDetails"
      :dialog="correctionTicketDialog"
      @correctionTicketSubmit="remarkTicketSubmit"
      @closeCorrectionDialog="closeCorrectionTicketDialog"
      @correctionTicketSuccess="correctionTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <UpdateTicketsDialogVups
      v-if="updateTicketDialog"
      :dialog="updateTicketDialog"
      :ticket-data="ticketDetails"
      @updateTicketSubmit="updateTicketSubmit"
      @closeUpdateTicket="closeUpdateTicketDialog"
      @updateTicketSuccess="updateTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />

    <ErrorMessage
      v-if="isError"
      :error-code="errorCode"
      :error-message="errorMessage"
      @ticketScreen="isError = false"
    />
  </div>
</template>

<script>
import moment from "moment";
import EditTicket from "@/mixins/EditTicket";
import TicketSecondaryFunctions from "@/mixins/TicketSecondaryFunctions";
export default {
  mixins: [EditTicket, TicketSecondaryFunctions],
  name: "TickDetails",
  components: {
    ErrorMessage: () => import("../../../../molecules/ErrorMessage.vue"),
    CancelTicketDialogVups: () => import("./CancelTicketDialogVups.vue"),
    CommentTicketDialogVups: () => import("./CommentTicketDialogVups.vue"),
    RemarkTicketDialogVups: () => import("./RemarkTicketDialogVups.vue"),
    HourNotice: () => import("./ThreeHourNoticeDialogVups.vue"),
    DeepNoticeDialog: () => import("./DeepNoticeDialog.vue"),
    CorrectionTicketDialogVups: () =>
      import("./CorrectionTicketDialogVups.vue"),
    UpdateTicketsDialogVups: () => import("./UpdateTicketsDialogVups.vue"),
  },
  props: {
    ticketDetails: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    cancelVups: false,
    updateTicketVups: false,
    threeHourNoticeTicket: false,
    threeHourDeepNoticeTicket: false,
    hourNoticeTicketDialog: false,
    correctionTicket: false,
    commentTicket: false,
    commentCO: false,
    remarkTicket: false,
    dialog: true,
    deepNoticeDialog: false,
    cancelTicketDialog: false,
    extendTicketDialog: false,
    secondNoticeTicketDialog: false,
    commentTicketDialog: false,
    correctionTicketDialog: false,
    retransmitTicketDialog: false,
    remarkTicketDialog: false,
    updateTicketDialog: false,
    ticketData: {},
    errorCode: "",
    errorMessage: "",
    isError: false,
  }),
  computed: {},
  async created() {
    this.moment = moment;
  },
  async mounted() {
    this.ticketData = Object.assign({}, this.ticketDetails);
    await this.checkSecondaryFunctionsEligibility();
  },
  methods: {
    errorSubmitTicket(data) {
      this.errorCode = data.errorCode.toString();
      this.errorMessage = data.error;
      this.isError = true;
      console.log(data);
    },
    retransmitTicket() {
      this.retransmitTicketDialog = true;
    },
    closeRetransmitTicketDialog() {
      this.retransmitTicketDialog = false;
    },
    retransmitTicketSubmit() {
      this.retransmitTicketDialog = false;
    },
    async retransmitTicketSuccess(
      retransmitTicketNumber,
      retransmitTicketRevision
    ) {
      this.retransmitTicketDialog = false;
      this.$emit(
        "retransmitTicketSuccess",
        retransmitTicketNumber,
        retransmitTicketRevision
      );
    },

    deepNoticeTicket() {
      this.deepNoticeDialog = true;
    },
    closeDeepNoticeDialog() {
      this.deepNoticeDialog = false;
    },
    deepNoticeSubmit() {
      this.deepNoticeDialog = false;
    },
    async deepNoticeSuccess(deepNoticeTicketNumber, deepNoticeTicketRevision) {
      this.deepNoticeDialog = false;
      this.$emit(
        "hourDeepNoticeTicketSuccess",
        deepNoticeTicketNumber,
        deepNoticeTicketRevision
      );
    },
    cancelTicket() {
      this.cancelTicketDialog = true;
    },
    closeCancelTicketDialog() {
      this.cancelTicketDialog = false;
    },
    cancelTicketSubmit() {
      this.cancelTicketDialog = false;
    },
    async cancelTicketSuccess(cancelTicketNumber, cancelTicketRevision) {
      this.cancelTicketDialog = false;
      this.$emit(
        "cancelTicketSuccess",
        cancelTicketNumber,
        cancelTicketRevision
      );
    },

    extendTicket() {
      this.extendTicketDialog = true;
    },
    closeExtendTicketDialog() {
      this.extendTicketDialog = false;
    },
    extendTicketSubmit() {
      this.extendTicketDialog = false;
    },
    async extendTicketSuccess(ticketNumber, ticketRevision) {
      this.extendTicketDialog = false;
      this.$emit("extendTicketSuccess", ticketNumber, ticketRevision);
    },

    secondNoticeTicket() {
      this.secondNoticeTicketDialog = true;
    },
    closeSecondNoticeTicketDialog() {
      this.secondNoticeTicketDialog = false;
    },
    secondNoticeTicketSubmit() {
      this.secondNoticeTicketDialog = false;
    },
    async secondNoticeTicketSuccess(ticketNumber, ticketRevision) {
      this.secondNoticeTicketDialog = false;
      this.$emit("secondNoticeTicketSuccess", ticketNumber, ticketRevision);
    },

    commentTicketClicked() {
      this.commentTicketDialog = true;
    },
    closeCommentTicketDialog() {
      this.commentTicketDialog = false;
    },
    commentTicketSubmit() {
      this.commentTicketDialog = false;
    },
    async commentTicketSuccess(commentTicketNumber, commentTicketRevision) {
      this.commentTicketDialog = false;
      this.$emit(
        "commentTicketSuccess",
        commentTicketNumber,
        commentTicketRevision
      );
    },
    correctionTicketClicked() {
      this.correctionTicketDialog = true;
    },
    closeCorrectionTicketDialog() {
      this.correctionTicketDialog = false;
    },
    correctionTicketSubmit() {
      this.correctionTicketDialog = false;
    },
    async correctionTicketSuccess(
      correctionTicketNumber,
      correctionTicketRevision
    ) {
      this.correctionTicketDialog = false;
      this.$emit(
        "correctionTicketSuccess",
        correctionTicketNumber,
        correctionTicketRevision
      );
    },
    hourNoticeTicket() {
      this.hourNoticeTicketDialog = true;
    },
    closeHourNoticeTicketDialog() {
      this.hourNoticeTicketDialog = false;
    },
    hourNoticeTicketSubmit() {
      this.hourNoticeTicketDialog = false;
    },
    async hourNoticeTicketSuccess(ticketNumber, ticketRevision) {
      this.hourNoticeTicketDialog = false;
      this.$emit("hourNoticeTicketSuccess", ticketNumber, ticketRevision);
    },
    updateTicket() {
      this.updateTicketDialog = true;
    },
    closeUpdateTicketDialog() {
      this.updateTicketDialog = false;
    },
    updateTicketSubmit() {
      this.updateTicketDialog = false;
    },
    async updateTicketSuccess(ticketNumber, ticketRevision) {
      this.updateTicketDialog = false;
      this.$emit("updateTicketSuccess", ticketNumber, ticketRevision);
    },

    getNextWorkDay(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    remarkTicketClicked() {
      this.remarkTicketDialog = true;
    },

    closeRemarkTicketDialog() {
      this.remarkTicketDialog = false;
    },
    remarkTicketSubmit() {
      this.remarkTicketDialog = false;
    },
    async remarkTicketSuccess(remarkTicketNumber, remarkTicketRevision) {
      this.remarkTicketDialog = false;
      this.$emit(
        "remarkTicketSuccess",
        remarkTicketNumber,
        remarkTicketRevision
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
